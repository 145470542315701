import React from "react";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    <LogoContainer>
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="200"
        height="73"
        viewBox="0 0 1500.46 283.46"
        enable-background="new 0 0 283.46 283.46"
      >
        <g>
          <path
            fill={fill}
            d="M226.318,84.957c-6.594,0-11.436,1.546-14.523,4.633c-2.924,2.923-3.466,5.468-1.627,7.638
		c5.423,6.845,15.587,36.407,14.264,58.376c-0.506,8.417-2.296,18.114-6.885,29.049c-2.172,5.175-4.738,10.037-7.7,14.586
		c-2.965,4.55-6.157,8.535-9.579,11.957c-3.424,3.424-7.012,6.115-10.767,8.075c-3.757,1.962-7.013,2.942-9.767,2.942
		c-2.755,0-4.802-0.5-6.135-1.503c-4.677-3.17-7.013-9.056-7.013-17.653c0-12.353,4.883-28.422,14.649-48.203l4.883-10.018
		c1.003-2.085,2.274-4.694,3.818-7.825c1.545-3.13,2.232-5.988,2.067-8.577c-0.251-4.257-4.008-6.51-11.27-6.761
		c-4.592-0.167-8.389,0.459-11.393,1.877c-1.837,0.836-3.382,2.714-4.634,5.635c-10.852,23.206-23.331,43.865-37.436,61.976
		c-12.939,16.527-23.331,26.127-31.176,28.798c1.167-6.928,4.464-17.361,9.891-31.302l6.886-17.277
		c2.42-5.843,4.612-11.477,6.574-16.904c1.96-5.424,3.588-10.558,4.883-15.4c1.293-4.839,1.94-9.202,1.94-13.084
		c0-3.881-1.545-7.199-4.632-9.954c-1.587-1.334-3.611-2.441-6.072-3.318c-2.464-0.876-5.844-1.315-10.142-1.315
		c-4.3,0-8.451,0.96-12.458,2.88c-2.003,1.001-3.611,2.171-4.82,3.505c-1.211,1.336-1.815,2.735-1.815,4.194
		c0,1.461,0.688,2.672,2.066,3.631c1.377,0.96,2.836,2.046,4.382,3.255c1.544,1.21,2.899,2.629,4.069,4.257
		c1.168,1.627,1.293,4.738,0.376,9.328c-0.92,4.591-3.506,12.688-7.763,24.29l-11.018,28.421c-1.671,4.426-3.361,9.185-5.071,14.274
		c-0.432,1.285-0.808,2.58-1.131,3.886c-0.439,0.307-0.882,0.615-1.348,0.934c-2.254,1.546-4.883,2.943-7.888,4.195
		c-3.005,1.252-6.345,1.878-10.017,1.878c-3.673,0-6.178-0.813-7.512-2.442c-1.336-1.627-2.003-3.899-2.003-6.823
		c0-10.432,41.725-113.388,41.725-113.388h4.546c44.383,3.697,96.361-5.509,96.361-16.527c0-1.752-1.191-2.629-3.569-2.629
		c-2.38,0-4.362,0.042-5.948,0.125l-7.137,0.125c-3.173,0.084-64.641,0.125-69.815,0.125H96.63l9.266-20.158
		c1.001-2.17,1.502-4.088,1.502-5.759c0-3.087-1.336-5.885-4.007-8.388c-1.17-1.084-2.504-1.92-4.007-2.504
		c-1.502-0.583-3.38-0.876-5.634-0.876s-4.3,0.669-6.135,2.003c-3.84,2.923-10.267,14.943-19.281,36.059l-32.846-0.13
		c-3.005,0-5.969,1.211-8.89,3.632c-1.252,1.085-2.275,2.212-3.067,3.38c-0.794,1.17-1.189,2.191-1.189,3.067
		s0.125,1.691,0.375,2.441c0.833,2.504,29.005,4.305,38.605,5.138C44.295,143.049,15.553,209.943,19.81,224.217
		c3.587,12.02,12.102,18.029,25.542,18.029c7.762,0,15.482-2.462,23.162-7.388c1.388-0.891,2.657-1.794,3.855-2.707
		c2.536,6.383,6.95,9.594,13.274,9.594c6.467,0,15.4-5.027,26.794-15.087c11.393-10.058,22.014-22.266,31.864-36.622
		c-1.002,5.258-1.502,10.642-1.502,16.15c0,24.04,9.306,36.06,27.921,36.06c10.849,0,22.618-5.258,35.307-15.775
		c5.927-4.925,11.699-10.633,16.966-17.403c11.038-14.191,20.24-33.479,25.876-54.451C254.504,133.643,249.31,84.957,226.318,84.957
		z"
          />
          <path
            fill={fill}
            d="M258.378,216.316c-1.653-0.795-3.415-1.379-5.282-1.745c-1.87-0.368-4.136-0.552-6.8-0.552s-5.191,0.98-7.58,2.939
		c-1.349,1.104-2.467,2.56-3.354,4.365c-0.89,1.807-1.332,3.859-1.332,6.155c0,2.298,0.396,4.319,1.193,6.065
		c0.796,1.745,1.884,3.293,3.262,4.639c1.379,1.349,3.018,2.405,4.916,3.17c1.897,0.766,4.365,0.996,7.397,0.689
		c3.031-0.307,5.956-2.052,8.774-5.237c3.001-3.43,4.502-7.442,4.502-12.037C264.075,221.094,262.177,218.277,258.378,216.316z"
          />
        </g>
      </svg>
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
